/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ICourseLesson } from '@utils/interfaces/courseLesson';
import { orderBy, chain } from 'lodash';

export const getMaxText = (text: string, max = 100): string => {
  if (text) {
    return text.substring(0, max).trim() + (text.length <= max ? '' : '...');
  }
  return '';
};
/* eslint-disable @typescript-eslint/no-explicit-any */
export const arrayToStringWithComma = (array: any[]) =>
  array.join().split(',').join(', ');

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function niceBytes(x): string {
  let l = 0;
  let n = parseInt(x, 10) || 0;

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export function formatToModuleWithCourseLessons(
  courseLessons: ICourseLesson[],
) {
  return chain(courseLessons)
    .groupBy(x => x?.courseModule.id)
    .filter(courseLesson => Boolean(courseLesson[0]?.courseModule))
    .map(courseLesson => {
      const { id, title, position } = courseLesson[0]?.courseModule;
      return {
        id,
        title,
        position,
        courseLessons: orderBy(courseLesson, ['position'], ['asc']),
      };
    })
    .orderBy('position', 'asc')
    .value();
}

export const formatExternalLink = (link: string, params: any = {}) => {
  let linkFormated = link && link.indexOf('?') > -1 ? `${link}&` : `${link}?`;
  linkFormated += Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');

  return linkFormated;
};

export const convertHourStringForMinutesNumber = (hour: string) => {
  return Number(hour.substr(0, 2)) * 60 + Number(hour.substr(3, 2));
};

export const convertMinutesNumberForHourString = (minutes: number) => {
  const hour = `00${Math.trunc(minutes / 60)}`.slice(-2);
  const min = `00${minutes % 60}`.slice(-2);
  return `${hour}:${min}`;
};
