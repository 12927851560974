import { Box } from '@material-ui/core';
import { ICourseItem } from '@utils/interfaces/course';
import React from 'react';
import { CircularProgress } from '@components/core';
import { Link } from '@components/ui';

import {
  ContainerMobile,
  ImgMobile,
  Title,
  ContainerProgress,
  ContainerDescriptionMobile,
  Button,
} from './styles';

interface CardMobileProps {
  course: ICourseItem;
  textButton?: string;
  showProgress?: boolean;
}

const CardMobile: React.FC<CardMobileProps> = ({
  course,
  textButton,
  showProgress,
}) => {
  return (
    <ContainerMobile>
      {showProgress && (
        <Box position="relative">
          <ContainerProgress>
            <CircularProgress value={course.progress} showLabel />
          </ContainerProgress>
        </Box>
      )}
      <ImgMobile src={course.bannerImageUrl} />
      <ContainerDescriptionMobile>
        <Title>{course.name}</Title>
        <Box width="100%" display="flex" justifyContent="center">
          <Link
            href={`/curso/${course.slug}`}
            noDecoration
            disabled={course.isExpired}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={course.isExpired}
            >
              {course.isExpired ? 'Curso Expirado' : textButton || 'Continuar'}
            </Button>
          </Link>
        </Box>
      </ContainerDescriptionMobile>
    </ContainerMobile>
  );
};

export default CardMobile;
