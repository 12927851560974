import { Link } from '@components/ui';
import { styled, Button as MaterialButton, Box } from '@material-ui/core';

export const HeaderContainer = styled('div')(({ theme }) => ({
  padding: '40px',
  '& h1': {
    fontSize: '2.25rem',
    fontWeight: 700,
    margin: 0,
    paddingBottom: 22,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      paddingBottom: 14,
    },
  },
  '& h3': {
    fontSize: '1.18rem',
    maxWidth: 900,
    color: '#000000',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      lineHeight: 1.5,
    },
  },
  [theme.breakpoints.down('sm')]: {
    margin: '28px 20px 42px 20px',
    padding: '20px 40px 20px 20px',
    background: '#fff',
    borderRadius: 13,
    boxShadow: '0px 0px 6px #0000001D',
    minHeight: 200,
  },
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  padding: '0 24px 32px',
  '& h2': {
    fontSize: '1.5rem',
    fontWeight: 600,
    paddingTop: 16,
  },
  '& h3': {
    maxWidth: 900,
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    margin: '28px 20px 42px 20px',
    padding: 0,
  },
}));
export const CourseItem = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(3),
  '& p:first-child': {
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: '20px',
    paddingLeft: '20px',
    width: '100%',
    textAlign: 'start',
  },
}));

export const CourseCard = styled('div')(({ theme }) => ({
  display: 'flex',
  border: '1px solid #E0E0E0',
  margin: '32px 0',
  borderRadius: 10,
  width: '70%',
  height: 124,
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    background: '#fff',
    borderRadius: 13,
    boxShadow: '0px 0px 6px #0000001D',
    padding: 40,
    minHeight: 200,
  },
  [theme.breakpoints.down('lg')]: {
    width: '95%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '32px auto',
    width: '100%',
    height: '100%',
    maxWidth: '550px',
    '& .progress': {
      position: 'absolute',
      background: '#fff',
      borderRadius: '50%',
      right: '12px',
      top: 'calc(214px - (78px/2))',
      margin: '0',
    },
  },
  '& img': {
    width: 190,
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px 0px 0px 10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      height: '214px',
      borderRadius: '10px 10px 0px 0px',
    },
  },
  '& h4': {
    color: '#000',
    fontWeight: 700,
    margin: 0,
    paddingBottom: 8,

    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
    },
  },
  '& h5': {
    color: '#000',
    fontWeight: 300,
    maxWidth: 530,
    margin: 0,
  },
}));

export const Button = styled(MaterialButton)(({ theme }) => ({
  borderRadius: 25,
  height: 50,
  alignSelf: 'center',
  minWidth: 170,
  fontFamily: 'Barlow, sans-serif',

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(4),
    fontSize: '0.9rem',
  },
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(4),
  },
}));

export const FeedbackCard = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 13,
  boxShadow: '0 0 6px #0000001D;',
  padding: '30px',
  margin: '20px ',
  maxWidth: 412,
  alignSelf: 'center',
}));
