import { ICourseItem } from '@utils/interfaces/course';
import { Link } from '@components/ui';
import { CircularProgress } from '@components/core';

import { Box } from '@material-ui/core';

import {
  Container,
  ContainerImg,
  Img,
  ContainerText,
  Description,
  Title,
  Button,
  ContainerProgress,
} from './styles';

interface CardDesktopProps {
  course: ICourseItem;
  textButton?: string;
  showProgress?: boolean;
}

const CardDesktop: React.FC<CardDesktopProps> = ({
  course,
  textButton,
  showProgress,
}) => {
  return (
    <>
      {showProgress && (
        <Box position="relative">
          <ContainerProgress>
            <CircularProgress value={course.progress} showLabel />
          </ContainerProgress>
        </Box>
      )}
      <Container>
        <ContainerImg>
          <Img src={course.cardImageUrl} alt={course.name} />
        </ContainerImg>

        <ContainerText>
          <Box>
            <Title>{course.name}</Title>
            <Description>{course.description}</Description>
          </Box>
          <Link
            href={`/curso/${course.slug}`}
            noDecoration
            disabled={course.isExpired}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={course.isExpired}
            >
              {course.isExpired ? 'Curso Expirado' : textButton || 'Continuar'}
            </Button>
          </Link>
        </ContainerText>
      </Container>
    </>
  );
};

export default CardDesktop;
