import { styled } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';

export const ContentDesktop = styled(ScrollContainer)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  scrollBehavior: 'smooth',
  height: '290px',

  '@media screen and (max-width: 600px)': {
    display: 'none',
  },
});

export const ContentMobile = styled('div')({
  display: 'none',
  '@media screen and (max-width: 600px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 40px',
  margin: '40px',
  borderRadius: '13px',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',

  '@media screen and (max-width: 600px)': {
    margin: '40px 20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0',
  },
});

export const Title = styled('h1')({
  fontFamily: 'Barlow',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#000000',
  margin: 0,

  '@media screen and (max-width: 600px)': {
    fontSize: '18px',
  },
});

export const ContainerTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '18px',
  justifyContent: 'space-between',
  width: '100%',
  margin: '10px 0',
});
