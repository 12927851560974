import { styled } from '@material-ui/core';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  justifyContent: 'space-between',
  marginRight: '20px',

  '@media screen and (max-width: 600px)': {
    display: 'none',
  },
});

export const ContainerArrow = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  minWidth: '37px',
  minHeight: '37px',
  cursor: 'pointer',
});
