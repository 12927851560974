import useSWR from 'swr';
import { AxiosRequestConfig } from 'axios';
import api from '@services/content/api';
interface IUseFetch<T> {
  data?: T;
  error?: any;
  mutate: (data?: T | Promise<T>, shouldRevalidate?: boolean) => Promise<T>;
}
export function useFetch<T = any>(
  url: string | string[],
  config?: AxiosRequestConfig,
): IUseFetch<T> {
  const { data, error, mutate } = useSWR<T>(url, async base => {
    try {
      const response = await api.get(base, config);
      return response.data;
    } catch (err) {
      return err;
    }
  });
  return { data, error, mutate };
}
