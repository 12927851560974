import React, { useCallback, useEffect, useState } from 'react';
import { NextPage, GetStaticProps } from 'next';

import { Head } from 'components/core';

import { Box, Typography } from '@material-ui/core';

import { useUser } from '@hooks/user';
import { useFetch } from '@hooks/fetch';

import { getMaxText } from '@utils/format';
import { IResponse } from '@utils/interfaces/response';
import { IStudentEnrollment } from '@utils/interfaces/studentEnrollment';
import { IStudentCourse } from '@utils/interfaces/studentCourses';

import Footer from '@components/core/Footer';
import { Link } from '@components/ui';

import { HeaderContainer, Button, FeedbackCard } from '@styles/home/styles';
import dayjs from 'dayjs';
import { useAuth } from '@hooks/auth';

import ContentCourse from '@components/core/ContentCourse';
import Completed from '@components/core/Completed';
import { ICourseItem } from '@utils/interfaces/course';

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

interface IStudentEnrollmentWithStudentCourses {
  studentEnrollment: IStudentEnrollment;
  studentCourse?: IStudentCourse;
}
const Home: NextPage = () => {
  const { t } = useTranslation('common');
  const { user } = useUser();
  const { setShowModalLogin } = useAuth();
  const [courseInProgress, setCourseInProgress] = useState<ICourseItem[]>([]);
  const [coursesToStart, setCoursesToStart] = useState<ICourseItem[]>([]);
  const { locale } = useRouter();
  const { data: studentEnrollments } = useFetch<IResponse<IStudentEnrollment>>(
    user?._id ? `/lms-student/student-enrollment?language=${locale}` : null,
  );
  const { data: studentCourses } = useFetch<IResponse<IStudentCourse>>(
    user?._id ? `/lms-student/student-course?language=${locale}` : null,
  );

  useEffect(() => {
    if (studentEnrollments?.rows?.length && studentCourses?.rows) {
      const { toStart, inProgress } = handleCourses(
        studentEnrollments.rows,
        studentCourses.rows,
      );
      setCoursesToStart(toStart);
      setCourseInProgress(inProgress);
    }
  }, [studentEnrollments, studentCourses]);

  const canAccess = useCallback(() => {
    const index = studentEnrollments?.rows?.findIndex(
      enrollment => enrollment?.course?._id,
    );
    return index !== -1;
  }, [studentEnrollments]);

  return (
    <>
      <Head>
        <title>StartSe</title>
      </Head>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100vh"
        style={{ background: '#F6F7F8' }}
      >
        <div>
          <Completed />
          <HeaderContainer>
            <Typography variant="h1" color="textPrimary">
              {t('home_welcome_message')}
              {user?.firstName ? `, ${user?.firstName}` : ''}!
            </Typography>
            <Typography variant="h3" color="textSecondary">
              {t('home_subtitle_message')}
            </Typography>
          </HeaderContainer>
          {!user?._id ? (
            <Box m="auto" maxWidth="452px">
              <FeedbackCard>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  textAlign="center"
                >
                  <Typography variant="h3" color="textSecondary">
                    <strong>{t('home_unauthenticated_title')}</strong>
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="h3" color="textSecondary">
                      {t('home_unauthenticated_message')}
                    </Typography>
                  </Box>
                  <Box mt={4}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setShowModalLogin(true)}
                    >
                      {t('login_button')}
                    </Button>
                  </Box>
                </Box>
              </FeedbackCard>
            </Box>
          ) : (
            <>
              {!canAccess() ? (
                <Box m="auto" maxWidth="452px">
                  <FeedbackCard>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      width="100%"
                      textAlign="center"
                    >
                      <Typography variant="h3" color="textSecondary">
                        <strong>{t('home_not_subscribe_title')}</strong>
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="h3" color="textSecondary">
                          {t('know_university_courses')}
                        </Typography>
                      </Box>
                      <Box mt={4}>
                        <Link
                          href={process.env.NEXT_PUBLIC_UNIVERSITY}
                          noDecoration
                        >
                          <Button color="primary" variant="contained">
                            {t('know_button')}
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </FeedbackCard>
                </Box>
              ) : (
                <>
                  {courseInProgress?.length > 0 && (
                    <ContentCourse
                      showProgress
                      title={t('home_ongoing_courses')}
                      courses={courseInProgress}
                    />
                  )}
                  {coursesToStart?.length > 0 && (
                    <>
                      <ContentCourse
                        title={t('home_to_start_courses')}
                        textButton={t('start_button')}
                        courses={coursesToStart}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div>
          <Footer />
        </div>
      </Box>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default Home;

function formatToCourseList({
  studentEnrollment,
  studentCourse,
}: IStudentEnrollmentWithStudentCourses): ICourseItem {
  return {
    name: getMaxText(studentEnrollment?.course?.name),
    subtitle: getMaxText(studentEnrollment?.course?.subtitle),
    description: getMaxText(studentEnrollment?.course?.description),
    bannerImageUrl: studentEnrollment?.course?.bannerImageUrl,
    cardImageUrl: studentEnrollment?.course?.cardImageUrl,
    slug: studentEnrollment?.course?.slug,
    id: studentEnrollment?.course?.id,
    lastAccessLessonSlug: studentCourse?.lastAccessLesson?.slug,
    isExpired: dayjs(studentEnrollment?.expiresAt).isBefore(dayjs()),
    progress: studentCourse?.progress ?? 0,
    sections: studentEnrollment?.course?.sections,
  };
}

function handleCourses(
  studentEnrollments: IStudentEnrollment[],
  studentCourses: IStudentCourse[],
) {
  const merged: IStudentEnrollmentWithStudentCourses[] = studentEnrollments.map(
    studentEnrollment => ({
      studentEnrollment,
      studentCourse: studentCourses.find(
        studentCourse =>
          studentCourse?.course?.id === studentEnrollment?.course?.id,
      ),
    }),
  );
  // filtra apenas quem possui student course
  const inProgress = merged
    .filter(
      ({ studentCourse, studentEnrollment }) =>
        !!studentCourse && studentEnrollment?.course?._id,
    )
    .map(m => formatToCourseList(m));

  // filtra apenas quem NÃO possui student course
  const toStart = merged
    .filter(
      ({ studentCourse, studentEnrollment }) =>
        !studentCourse && studentEnrollment?.course?._id,
    )
    .map(m => formatToCourseList(m));

  return { toStart, inProgress };
}
