import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Container, ContainerArrow } from './styles';

interface ArrowControllProps {
  currentScroll: number;
  maxScroll: number;
  onPressNext(): void;
  onPressPrev(): void;
}

const ArrowControl: React.FC<ArrowControllProps> = ({
  currentScroll,
  maxScroll,
  onPressNext,
  onPressPrev,
}) => {
  return (
    <Container>
      <ContainerArrow onClick={onPressPrev}>
        <ChevronLeft color={currentScroll <= 10 ? 'disabled' : 'inherit'} />
      </ContainerArrow>
      <ContainerArrow onClick={onPressNext}>
        <ChevronRight
          color={currentScroll >= maxScroll - 100 ? 'disabled' : 'inherit'}
        />
      </ContainerArrow>
    </Container>
  );
};

export default ArrowControl;
